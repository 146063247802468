import { OnInit, Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FallAlertDataService } from "../../../services/data/data.fall-alert.service";
import { WizardComponent } from "../../../shared/wizard/wizard.component";
import { ParsedNumber, formatNumber, getCountryCallingCode, parseNumber } from "libphonenumber-js";
import { PhoneNumberPipe } from "../../../shared/pipes/phone-number.pipe";
import * as _ from "lodash";
import { CatService } from "../../../services/cat/cat.service";
import { FallHelper } from "../fall-helpers/fall-helper";
import { ContactMapHosting } from "../../../models/fall-alert/fall-alert-interfaces";

@Component({
    selector: "fall-opt-out-contact",
    templateUrl: "./fall-opt-out-contact.component.html",
    styleUrls: ["../fall-management.scss"]
})
export class FallOptOutContactComponent implements OnInit {

    public isPageLoading: boolean = false;
    public awaitingResponse: boolean = false;
    public invalidPhoneNumber: boolean = false;
    public invalidVerificationCode: boolean = false;
    public contactMappingData: ContactMapHosting[] = [];
    public currentStep: number = 1;
    public appBrand: string = "MyStarkey";
    public userCountry: any;

    @ViewChild("fallOptOutContactWizard")
    private wizard: WizardComponent;
    public phoneNumber: string = null;
    public verificationCode: string = "";
    public formattedPhoneNumber: string = "";

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _toastr: ToastrService,
        private _fallAlertDataService: FallAlertDataService,
        public _catService: CatService,
        public _fallHelper: FallHelper) {
            this.setCountryCode();
    }

    ngOnInit() {
        this.loadCatFile();
    }

    onCurrentStepChange($event) {
        this.currentStep = $event + 1;
    }

    goToNextStep() {
        this.wizard.goToNextStep();
    }

    verifyPhoneNumber(leadingZero: boolean = false) {
        const func = async () => {
            this.awaitingResponse = true;
    
            while (true) {
                const parsedNumber = parseNumber(`Phone: ${this.phoneNumber}.`, this.userCountry) as ParsedNumber;
    
                if (!parsedNumber.phone) {
                    this._toastr.error(
                        this._catService.getString("CloudTray_FallManagement_FallOptOutContact_CannotVerifyPhoneError")
                    );
                    this.awaitingResponse = false;
                    return false;
                }
    
                if (leadingZero) {
                    this.formattedPhoneNumber = `+${getCountryCallingCode(this.userCountry)}0${parsedNumber.phone}`
                } else {
                    this.formattedPhoneNumber = formatNumber(parsedNumber.phone, parsedNumber.country, 'E.164');
                }
    
                try {
                    await this._fallAlertDataService
                        .validateContactOptOutPhoneNumber(this.formattedPhoneNumber)
                        .toPromise();
    
                    this.awaitingResponse = false;
                    return true;
                } catch (error) {
                    
                    // try again with leading 0 for non-us (primarily European) numbers
                    if (!leadingZero && this.userCountry != "US") {
                        leadingZero = true; 
                        continue;
                    }
    
                    this.awaitingResponse = false;
    
                    if (error.status === 401) {
                        this.invalidPhoneNumber = true;
                        return false;
                    }
    
                    this._toastr.error(
                        this._catService.getString(
                            "CloudTray_FallManagement_FallOptOutContact_InvalidVerificationCodeError"
                        )
                    );
                    return false;
                }
            }
        };
    
        return func.bind(this);
    }
    
    

    checkVerificationCode() {
        let func = () => {
            this.awaitingResponse = true;
            return this._fallAlertDataService
            .getFallAlertContactMapping(this.verificationCode, this.formattedPhoneNumber)
            .toPromise()
            .then((data) => {
                data.contactMaps.forEach(contactMap => {
                    const contactMapHosting: ContactMapHosting = {
                        uid: contactMap.uid,
                        contactId: contactMap.contactId,
                        patientName: contactMap.patientName,
                        isBusy: false
                    };

                    this.contactMappingData.push(contactMapHosting);
                });

                this.invalidVerificationCode = false;
                this.awaitingResponse = false;
                return Promise.resolve(true);
            })
            .catch(error => {
                this.awaitingResponse = false;
                if (error.status === 401) {
                    this.invalidVerificationCode = true;
                    return Promise.resolve(false);
                }

                this._toastr.error(this._catService.getString("CloudTray_FallManagement_FallOptOutContact_InvalidVerificationCodeError"));
                return Promise.resolve(false);
            });
        };
        return func.bind(this);
    }

    transformPhoneNumber() {
        this.phoneNumber = new PhoneNumberPipe().transform(this.phoneNumber, this.userCountry);
    }

    isProperNumber() {
        let parsedNumber = parseNumber(`Phone: ${this.phoneNumber}.`, this.userCountry) as ParsedNumber;
        return parsedNumber.phone ? true : false;
    }

    removeContact(item: ContactMapHosting) {
        item.isBusy = true;

        this._fallAlertDataService
            .deleteFallAlertContact(item.uid, item.contactId)
            .subscribe(deleted => {
                _.remove(this.contactMappingData, r => {
                    return r.uid === item.uid && r.contactId === item.contactId;
                });
            }, error => {
                this._toastr.error(this._catService.getString("CloudTray_FallManagement_FallOptOutContact_CannotRemoveUserError"));
                item.isBusy = false;
            });
    }

    changeToUpperCase() {
        this.verificationCode = this.verificationCode.toUpperCase();
    }


    async setCountryCode() {
        const ipInfo = await this._fallAlertDataService.getUserIpInfo().toPromise().catch(r => Promise.resolve({countryCode: 'US'}));
        this.userCountry = ipInfo.countryCode;
    }

    async loadCatFile() {
        const langs = this._fallHelper.getUserLanguages();
        for (const language of langs) {
            if (await this._catService.setLocale(language)) {
                break;
            }
        }
    }

    getWizardButtonClass() {
        if ((this.currentStep === 1 && !this.isProperNumber()) || (this.currentStep === 2 && !this.verificationCode) || this.awaitingResponse) {
            return this.getBrandPrefix() + "fall-management-btn-disabled";
        } else if (((this.currentStep === 1 && this.isProperNumber()) || (this.currentStep === 2 && this.verificationCode)) && !this.awaitingResponse) {
            return this.getBrandPrefix() + "fall-management-btn";
        }
    }

    getRemoveButtonClass(item) {
        return (item && item.isBusy) ? this.getBrandPrefix() + "fall-management-btn-disabled" : this.getBrandPrefix() + "fall-management-btn";
    }

    getBrandPrefix() {
        return this.appBrand === "KIND" ? "KIND-" : "";
    }
}